import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { TextAnimationHeading } from "../../components/text-animation-heading";
import { TextAnimationBody } from "../../components/text-animation-body";
import { Button } from "../../components/button";
import Reveal from "react-reveal/Reveal";
import { HtmlText } from "../../components/html-text";
import { Parallax } from "../../components/parallax";
import { TextShadow } from "../../components/text-shadow";
import { useMediaQuery } from "react-responsive";

export const ImageHeader = (props) => {
  const imageElement = useRef(null);
  const _classNames = classNames({
    "image-header": true,
  });

  const [loaded, setLoaded] = useState(false);

  const mobileView = useMediaQuery({ query: "(max-width:720px)" });

  useEffect(() => {
    if (imageElement.current.complete) setLoaded(true);
  }, [imageElement])

  return (
    <Parallax header={true}>
      <section className={_classNames}>
        <figure
          aria-hidden="true"
          className={`image-header__background-image level-1 
          ${loaded && "image-header__background-image--has-loaded"}`}
        >
          <img
            ref={imageElement}
            onLoad={() => setLoaded(true)}
            src={
              mobileView
                ? `${props.backgroundimage.url}?fit=crop&w=375&h=815&fm=jpg`
                : `${props.backgroundimage.url}?w=2000&fm=jpg`
            }
          />
        </figure>
        <div className="image-header__content">
          <div className="image-header__eyebrow level-5">
            <span>{props.eyebrow}</span>
          </div>
          <TextShadow shadow={props.shadow} />
          <h1 className="image-header__title level-4">
            <TextAnimationHeading textStyle="page-title" text={props.title} />
          </h1>
          <div className="image-header__body level-3">
            <TextAnimationBody textStyle="large-copy-4" delay={165}>
              <HtmlText html={props.body} />
            </TextAnimationBody>
          </div>
          {props.ctaButton && (
            <div className="image-header__button level-2">
              <Button
                internal={props.ctaButtonLink?.slug}
                external={props.ctaButtonExternalLink}
                text={props.ctaButton}
                primaryDark
              />
            </div>
          )}
        </div>

        <div
          className={`image-header__icons ${props.gradient && "image-header__icons--gradient"
            }`}
        >
          <div className="icons-container">
            {props.iconList.map((item, index) => (
              <div className="icons-container__item level-2">
                <img
                  className="icons-container__item__image"
                  alt={item.body}
                  src={item.icon.url}
                />
                <div className="icons-container__item__label">{item.body}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Parallax>
  );
};
