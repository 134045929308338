import React, { useState } from "react";
import classNames from "classnames";
import ArrowDown from "./arrow-down.inline.svg";
import Reveal from "react-reveal/Reveal";
import { useCountUp } from "react-countup";

const StartsBox = (props) => {
  const startValue = props.value > 100 ? props.value * 0.95 : props.value * 0.5;

  const { countUp, start, pauseResume, reset, update } = useCountUp({
    start: startValue,
    end: props.value,
    delay: 1000,
    duration: 1,
    delay: 0.165 * 0.5,
    separator: ",",
    decimal: ".",
    prefix: props.prefix,
    suffix: props.suffix,
    useEasing: true,
    easingFn: (t = 0, b = 0, c = 0, d = 1) =>
      c * ((t = t / d - 1) * t * t + 1) + b,
  });

  return (
    <Reveal effect="react-reveal--visible" onReveal={start} fraction={.8} delay={300}>
      <div className="stats__box">
        <p className="stats__eyebrow stats__eyebrow--color-blue">
          {props.eyebrow}
        </p>
        <div className="stats__number stats__number--color-blue">
          <span className="stats__value">{countUp}</span>
        </div>
        <p className="stats__sub-copy stats__sub-copy--color-blue">
          {props.describtion}
        </p>
      </div>
    </Reveal>
  );
};

export const Stats = (props) => {
  const _classNames = classNames({
    stats: true,
  });

  const [expandText, setExpandText] = useState("View more");

  const handleExpand = () => {
    const expandButton = document.querySelector(".stats__expand");
    const expandBoxes = document.querySelector(".stats__boxes");

    if (expandButton) {
      expandButton.classList.toggle("stats__expand--active");
      expandBoxes.classList.toggle("stats__boxes--expanded");
      setExpandText(expandText === "Close" ? "View more" : "Close");
    }
  };

  return (
    <section className={_classNames}>
      <div
        className={
          "stats__container " +
          (props.statsItems.length === 8
            ? "stats__container--large-layout"
            : "stats__container--small-layout")
        }
      >
        <div className="stats__boxes">
          {props.statsItems.map((item, index) => <StartsBox {...item} />)}
        </div>
        <div role="button" className="stats__expand" onClick={handleExpand}>
          <span className="stats__expand-text">{expandText}</span>
          <ArrowDown className="stats__arrow-down" />
        </div>
      </div>
    </section>
  );
};
