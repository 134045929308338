import React from 'react';
import classNames from 'classnames';
import { HtmlText } from '../../components/html-text';
import { SocialShares } from '../../components/social-shares';

export const ModularContent = (props) => {
  const _classNames = classNames({
    'modular-content': true
  });

  const moduleConductor = (module) => {
    switch (module.__typename) {
      case 'DatoCMS_ContentTextRecord':
        return <ModuleText {...module} />
      case 'DatoCMS_ContentImageRecord':
        return <ModuleImage {...module} />
      case 'DatoCMS_ContentVideoRecord':
        return <ModuleVideo {...module} />
    }
  };

  return(
    <section className={_classNames}>
      <div className="modular-content__container">
        <div className="modular-content__container__row">
          { props.content.map(module => moduleConductor(module)) }

          <div className="module">
            <SocialShares />
          </div>
        </div>
      </div>
    </section>
  )
}

const ModuleText = (props) => {
  return (
    <div className="module module-text">
      <HtmlText html={props.text} />
    </div>
  )
}

const ModuleImage = (props) => {
  const { keepOriginalSize } = props;

  const className = classNames({
    'module': true,
    'module--wide': true,
    'module-image': true,
    'module-image--keep-original-size': keepOriginalSize
  })

  return (
    <div className={className}>
      <figure>
        <img src={props.image.url} />
      </figure>
    </div>
  )
}

const ModuleVideo = (props) => {
  const youtubeId = (url) => {
    if (url.indexOf('youtube') === -1) return false;

    function getId (url) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length === 11) return match[2];
      else return 'error';
    }

    return getId(url);;
  }

  const vimeoId = (url) => {
    if (url.indexOf('vimeo') === -1) return false;
    var videoId = url.split('/').reverse()[0];
    return videoId;
  }

  return (
    <div className="module module--wide module-video">
      <div className="module-video__wrapper">
        { youtubeId(props.url) && <iframe className="youtube" width="560" height="315" src={`//www.youtube.com/embed/${youtubeId(props.url)}`} frameBorder="0" allowFullScreen /> }
        { vimeoId(props.url) && <iframe className="youtube" width="560" height="315" src={`//player.vimeo.com/video/${vimeoId(props.url)}`} frameBorder="0" allowFullScreen /> }
      </div>
    </div>
  )
}
