import React from 'react';
import Dropzone from 'react-dropzone'

export class FileUpload extends React.Component {
  state = {
    files: []
  }

  onDrop = (acceptedFiles) => {
    let files = [...this.state.files, ...acceptedFiles];
    this.setState({ files });
    this.props.onChange(files);
  }

  remove = (e, index) => {
    e.preventDefault();
    let files = [...this.state.files];
    files.splice(index, 1);
    this.setState({ files });
    this.props.onChange(files);
  }

  render () {
    return (
      <div className="form-field contact-form__form-field">
        <div className="form-field__label">
          <p className="form-field__label__text">{ this.props.label }</p>
          <div className="form-field__dropzone__files">
            { this.state.files.map((file, index) => (
              <div>{ file.name } <a href="#" onClick={(e) => this.remove(e, index)}>(remove)</a></div>
            )) }
          </div>

          { /* Add a couple of hidden file uploads to fool netlify */}
          <input type="file" name="attachment-1" style={{ display: 'none' }} />
          <input type="file" name="attachment-2" style={{ display: 'none' }} />
          <input type="file" name="attachment-3" style={{ display: 'none' }} />
          <input type="file" name="attachment-4" style={{ display: 'none' }} />
          <input type="file" name="attachment-5" style={{ display: 'none' }} />
          <input type="file" name="attachment-6" style={{ display: 'none' }} />
          <input type="file" name="attachment-7" style={{ display: 'none' }} />
          <input type="file" name="attachment-8" style={{ display: 'none' }} />
          <input type="file" name="attachment-9" style={{ display: 'none' }} />
          
          <Dropzone onDrop={ this.onDrop }>
            {({getRootProps, getInputProps}) => (
              <div className="form-field__dropzone">
                <div {...getRootProps()}>
                 
                  <input {...getInputProps()} />
                  <p>Drag & drop or <u>browse</u></p>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    )
  }
}