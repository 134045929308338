import React from "react";
import classNames from "classnames";
import Reveal from "react-reveal/Reveal";
import { Link } from "gatsby";

import { TextAnimationHeading } from "../../components/text-animation-heading";
import { TextAnimationBody } from "../../components/text-animation-body";
import { Button } from "../../components/button";

// Icons
import Download from "./download.inline.svg";
import External from "./external.inline.svg";
import Internal from "./goto.inline.svg";

export const ResourceList = (props) => {
  const _classNames = classNames({
    "resource-list": true,
  });

  return (
    <section className={_classNames}>
      <div className="resource-list__wrapper">
        <div className="resource-list__container">
          <div className="resource-list__sidebar">
            <Reveal effect="react-reveal--visible">
              <h2 className="resource-list__title">
                <TextAnimationHeading
                  textStyle="heading-2 text-animation-heading--color-grey"
                  text={props.title}
                />
              </h2>
              <div className="resource-list__body">
                <TextAnimationBody textStyle="sub-copy">
                  {props.body}
                </TextAnimationBody>
              </div>
            </Reveal>
          </div>
          <div className="resource-list__content">
            {props.listitems.map((item) => (
              <a
                href={ item.external || item.internal?.slug || item.download?.url }
                className="resource-list__item resource-list__item--border"
                target="_blank"
              >
                <div className="resource-list__item-content">
                  <h4 className="resource-list__heading">
                    {item.resourceTitle}
                  </h4>
                  <p className="resource-list__body">{item.resourceBody}</p>
                </div>
                <div className="resource-list__icon-container resource-list__icon-container--animated resource-list__icon-container--border">
                  {item.external ? (
                    <External className="related-links__icon related-links__icon--external" />
                  ) : item.download ? (
                    <Download className="related-links__icon " />
                  ) : item.internal ? (
                    <Internal className="related-links__icon" />
                  ) : null}
                </div>
              </a>
            ))}
            {
              /*
                <div href={props.viewAll.slug} className="resource-list__button">
                  <Button
                    internal={props.viewAll.slug}
                    text={"load more"}
                    secondary
                  />
                </div>
              */
            }
          </div>
        </div>
      </div>
    </section>
  );
};
