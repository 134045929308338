import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import LeftArrow from './left-arrow.inline.svg';
import RightArrow from './right-arrow.inline.svg';
import Reveal from 'react-reveal/Reveal';
import SplitType from 'split-type';
import { Link } from 'docz';
import Draggable from 'react-draggable';

const TextAnimationCardBody = (props) => {
  const el = useRef(null);

  const _classNames = classNames({
    'card-carousel__sub-copy': true,
    'card-carousel__sub-copy--color-white': true,
    [`as-${props.textStyle}`]: true
  });

  const initialDelay = props.delay || 0;
  const delay = 67;

  useEffect(() => {
    setTimeout(() => {
      let paragraphs = el.current.querySelectorAll('p');
      if (paragraphs.length === 0) {
        const text = new SplitType(el.current, { types: 'lines' });
        text.lines.forEach((letter, index) => letter.style.animationDelay = `${initialDelay + (delay * index)}ms`);
      } else {
        let counter = 0;
        paragraphs.forEach(paragraph => {
          const text = new SplitType(paragraph, { types: 'lines' });
          text.lines.forEach((letter, index) => {
            counter++;
            letter.style.animationDelay = `${initialDelay + (delay * counter)}ms`
          });
        })
      }
      el.current.classList.add('card-carousel__sub-copy--parsed');
    }, 200)

  }, [el]);

  return (
    <span className={_classNames} ref={el}>{props.children}</span>
  )
}

var slider, total, scrollbar, scrollbarWidth, trackWidth, trackerWidth, tracker, cardWidth, card;

export const CardCarousel = (props) => {


  const [position, setPosition] = useState('beginning');
  const [transition, setTransition] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  const _classNames = classNames({
    'card-carousel': true,
    'card-carousel--no-transition': !transition
  });


  const onScroll = (e) => {
    resize();
    const position = e.target.scrollLeft;
    const relative = position / total;

    if (relative === 0) setPosition('beginning');
    else if (relative === 1) setPosition('end');
    else setPosition('moving');

    setScrollPosition(relative * scrollbarWidth);
  };

  const resize = () => {
    trackerWidth = tracker.getBoundingClientRect().width;
    scrollbarWidth = scrollbar.getBoundingClientRect().width - trackerWidth;
    trackWidth = slider.getBoundingClientRect().width;
    total = slider.scrollWidth - trackWidth;
    cardWidth = card.getBoundingClientRect().width;
  };

  useEffect(() => {
    slider = document.querySelector('.card-carousel__track');
    scrollbar = document.querySelector('.card-carousel__scrollbar');
    tracker = document.querySelector('.card-carousel__scrollbar__tracker');
    card = document.querySelector('.card-carousel__card');

    slider.addEventListener('scroll', onScroll);
    window.addEventListener('resize', resize);
    resize();

    return () => {
      slider.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', resize);
    };
  }, []);

  const next = () => {
    const left = cardWidth + 25;
    slider.scrollBy({ left, behavior: 'smooth' });
  }

  const prev = () => {
    const left = (cardWidth + 25) * -1;
    slider.scrollBy({ left, behavior: 'smooth' });
  }

  const drag = (e, data) => {
    slider.scrollTo({ left: (data.x / scrollbarWidth) * total })
  }

  return (
    <section className={_classNames}>
      <div className="card-carousel__center">
        <Reveal effect="react-reveal--visible" fraction={0.5}>
          <div className="card-carousel__track">
            {props.carouselCards.map((item, index) =>
              <div className="card-carousel__card">
                <figure>
                  <img src={item.image.url} alt={item.image.alt} />
                </figure>
                <div className="card-carousel__content">
                  <p className="card-carousel__body card-carousel__body--color-white">{item.body}</p>
                  <TextAnimationCardBody textStyle="sub-copy">{item.subBody}</TextAnimationCardBody>
                  <span className="card-carousel__slide-index">{index < 9 ? "0" + (index + 1) : (index + 1)}</span>
                </div>
              </div>
            )}
            <div className="card-carousel__track__ghost" />
          </div>
        </Reveal>
      </div>
      <div className="card-carousel__center">
        <Reveal effect="react-reveal--visible" fraction={0.5}>
          <div className="card-carousel__bottom">
            <div className="card-carousel__scrollbar">
              <Draggable
                axis="x"
                bounds="parent"
                defaultPosition={{ x: 0, y: 0 }}
                position={{ x: scrollPosition, y: 0 }}
                scale={1}
                onDrag={drag}
              >
                <div className="card-carousel__scrollbar__tracker" />
              </Draggable>
            </div>

            <div className="scroll-arrow-container">
              <LeftArrow role="button" className={`scroll-arrow scroll-arrow--prev ${position === 'beginning' && 'scroll-arrow--disabled'}`} onClick={prev} role="button" tabindex="0"/>
              <RightArrow role="button" className={`scroll-arrow scroll-arrow--next ${position === 'end' && 'scroll-arrow--disabled'}`} onClick={next} role="button" tabindex="0"/>
            </div>
          </div>
        </Reveal>
      </div>
    </section>
  )
}
