import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { HtmlText } from '../../components/html-text';
import { TextAnimationBody } from '../../components/text-animation-body/index';
import { TextAnimationHeading } from '../../components/text-animation-heading';
import Reveal from 'react-reveal/Reveal';
import { Button } from '../../components/button/index';
import { useMediaQuery } from "react-responsive";


export const ContentBlockWithLargeImage = (props) => {

  const _classNames = classNames({
    'content-block-with-large-image': true,
    'content-block-with-large-image--flipped': props.reversedLayout,
    'content-block-with-large-image--centered': props.centeredContent,
    'content-block-with-large-image--bigger-body': props.biggerBody,
    'content-block-with-large-image--contain-image': props.containImage,
    [`content-block-with-large-image--${props.color}`]: true
  });

  const mobileView = useMediaQuery({ query: '(max-width:720px)' });
  const revealFraction = mobileView ? .25 : .45

  return (
    <section className={_classNames}>
      <div className="content-block-with-large-image__container">
        <div className="content-block-with-large-image__container__row">
          <Reveal effect="react-reveal--visible" fraction={revealFraction}>
            <div className="content-block-with-large-image__content">
              {props.eyebrow &&
                <div className="content-block-with-large-image__eyebrow">{props.eyebrow}</div>
              }

              {props.title &&
                <h2 className="content-block-with-large-image__title">
                  <TextAnimationHeading text={props.title} textStyle="heading-2" />
                </h2>
              }

              <div className="content-block-with-large-image__body">
                <TextAnimationBody textStyle={props.biggerBody ? "large-copy-1" : "sub-copy"} delay={165}>
                  <HtmlText html={props.body} />
                </TextAnimationBody>
              </div>

              {props.buttonText &&
                <div className="content-block-with-large-image__button">
                  {props.color === 'night-blue' || props.color === 'dark' ?
                    <Button internal={props.buttonLink?.slug} text={props.buttonText} secondaryOutline /> :
                    props.color === 'white' ?
                      <Button internal={props.buttonLink?.slug} text={props.buttonText} secondaryOutlineDark /> :
                      <Button internal={props.buttonLink?.slug} text={props.buttonText} secondaryDark />
                  }
                </div>
              }

              {props.iconListTitle &&
                <div className="content-block-with-large-image__icons">
                  <div className="content-block-with-large-image__icons-title">{props.iconListTitle}</div>
                  <div className="content-block-with-large-image__icons__container">
                    {props.iconListItems.map((item, i) =>
                      <div className="content-block-with-large-image__icons__container__item" key={"icon" + i} >
                        <img aria-hidden="true" className="content-block-with-large-image__icons__container__icon" src={item.icon.url} />
                        <div className="content-block-with-large-image__icons__container__content">
                          <div className="content-block-with-large-image__icons__container__title as-sub-copy-title">
                            {item.title}
                          </div>
                          <div className="content-block-with-large-image__icons__container__body">
                            <TextAnimationBody textStyle="micro-copy" delay={250}>
                              <HtmlText html={item.body} />
                            </TextAnimationBody>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </Reveal>


          <Reveal effect="react-reveal--visible" fraction={revealFraction}>
            <div className="content-block-with-large-image__image">
              <picture>
                <img src={props.image.url + '?w=1000&fm=jpg'} alt={props.image.alt} />
              </picture>
            </div>
          </Reveal>

        </div>
      </div>
    </section>
  )
}
