import React from 'react';
import classNames from 'classnames';
import { Cards } from '../cards';
import { Button } from '../../components/button';
import { useStaticQuery } from 'gatsby';

export const Press = ({ title }) => {
  const data = useStaticQuery(graphql`
    query PressComponentQuery {
      dato {
        allReleases: allPressReleases {
          __typename
          title
          slug
          author
          date
          image {
            url(imgixParams: { w: 746, h: 380, fit: crop  })
            alt
          }
        }
      }
    }
  `)

  const releases = data.dato.allReleases;

  const _classNames = classNames({
    'press': true
  });

  return(
    <section className={_classNames}>
      <div className="press__wrapper">
        <h2 className="press__heading press__heading--color-grey">{title}</h2>
      </div>
      <div className="press__cards-container">
        <Cards cards={releases} colorMode={'light'} containerType={'news'} press colorMode="grey-50"/>
      </div>

      {
        /*
          Not needed for now
          <div className="press__cta">
            <Button text={"Discover"} secondary />
          </div>
        */
      }
      
    </section>
  )
}
