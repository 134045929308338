import React, {useState, useRef} from 'react';
import { useScrollPercentage } from 'react-scroll-percentage';
import classNames from 'classnames';
import Reveal from 'react-reveal/Reveal';
import { TextAnimationHeading } from '../../components/text-animation-heading';
import { TextAnimationBody } from '../../components/text-animation-body';
import { HtmlText } from '../../components/html-text';

export const ImageContent = (props) => {
  var [pane, percentage = 0] = useScrollPercentage();
  const [visible, setVisible] = useState(false);

  const _classNames = classNames({
    'image-content': true,
    'react-reveal--visible': visible
  });

  percentage = Math.min(percentage / 0.5, 1);
  const scale = 1.2 - (0.2 * percentage);

  if (percentage >= 0.2 && !visible) setVisible(true);

  return (
    <section className={_classNames} ref={pane}>
      <figure className="image-content__background-image" style={{ transform: `scale3d(${ scale },${ scale },1)` }}>
        <img src={props.backgroundimage.url} alt={props.backgroundimage.alt} />
      </figure>
      <div className="image-content__container--primary">
        {
          /*
          
          // Removed but might be re-introduced at some point 
           {props.eyebrow &&
              <p className="image-content__eyebrow">{props.eyebrow}</p>
            }
            {props.title &&
              <div className="image-content__title">
                <TextAnimationBody textStyle="heading-2">
                  {props.title}
                </TextAnimationBody>
              </div>
            }
            {props.body &&
              <div className="image-content__description">
                <TextAnimationBody textStyle="sub-copy" delay={165}>
                  <HtmlText html={props.body} />
                </TextAnimationBody>
              </div>
            }

          */
        }
       
      </div>
      <div className="image-content__container--secondary">
        {props.iconList.map((item, index) =>
          <div className="image-content__icon__item">
            <img className="image-content__icon__item__image" alt={item.image.alt} src={item.image.url} />
            <div className="image-content__icon__item__content">
              <div className="image-content__icon__item__title">{item.title}</div>
              <div className="image-content__icon__item__body">{item.body}</div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
