import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { TextAnimationHeading } from '../../components/text-animation-heading';
import Reveal from 'react-reveal/Reveal';

export const RelatedLinks = (props) => {

  const _classNames = classNames({
    'related-links': true
  });

  return (
    <section className={_classNames}>
      <h2 className="related-links__title related-links__title--color-grey">
        <Reveal effect="react-reveal--visible">
          <TextAnimationHeading textStyle="heading-4" text={props.title} />
        </Reveal>
      </h2>
      {props.linkItem.map((item, i) =>
        <Link to={item.slug.slug} className="related-links__item related-links__item--border-bottom" >
          <div className="related-links__item-content">
            <figure className="related-links__item-image">
              <img role="button" src={item.image.url + '?w=264&h=150&fit=crop&fm=jpg&dpr=2'} alt={item.image?.alt} />
            </figure>
            <span className="related-links__item-title related-links__item-title--color-grey">{item.body}</span>
            <div className="related-links__icon-container">
              <span className="related-links__arrow related-links__arrow--animated"></span>
            </div>
          </div>
        </Link>
      )}
    </section>
  )
}
