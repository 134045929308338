import React from 'react';
import classNames from 'classnames';
import Reveal from 'react-reveal/Reveal';
import { TextAnimationBody } from '../../components/text-animation-body';
import { HtmlText } from '../../components/html-text';


export const OverlappingImages = (props) => {

  const _classNames = classNames({
    'overlapping-images': true,
    'overlapping-images--flipped': props.reversedLayout,
    [`overlapping-images--${props.bgColor[0]}`]: true
  });

  return (
    <section className={_classNames}>
      <Reveal effect="react-reveal--visible" fraction={0.5}>
        <div className="overlapping-images__container">

          <div className="overlapping-images__container__row">
            <div className="overlapping-images__container__col">
              <div className="overlapping-images__content">
                <div className="overlapping-images__body">
                  <TextAnimationBody textStyle="large-copy-1" delay={165}>
                    <HtmlText html={props.body} />
                  </TextAnimationBody>
                </div>
              </div>
            </div>

            <div className="overlapping-images__container__col">
              <div className="overlapping-images__images">
                <img className="overlapping-images__images--primary" src={props.imagePrimary?.url + '?w=1000&fm=jpg'} alt={props.imagePrimary?.alt} />
                <img className="overlapping-images__images--secondary" src={props.imageSecondary?.url  + '?w=1000&fm=jpg' } alt={props.imageSecondary?.alt} />
              </div>
            </div>

          </div>
        </div>
      </Reveal>
    </section>
  )
}
