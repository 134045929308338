import React, { useEffect } from 'react';
import classNames from 'classnames';
import Reveal from 'react-reveal/Reveal';

export class ProcessInfographic extends React.Component {
  state = {
    currentChapterIndex: 0,
    previousChapterIndex: 0,
    seeking: false,
    video: this.props.videoDesktop.url
  }

  get currentChapter() {
    return this.props.chapters[this.state.currentChapterIndex];
  }

  get previousChapter() {
    return this.props.chapters[this.state.previousChapterIndex];
  }

  componentDidMount() {
    this.refs.video.addEventListener('timeupdate', this.videoUpdate);
    this.refs.ghost.addEventListener('timeupdate', this.videoUpdateGhost);
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    this.refs.video.removeEventListener('timeupdate', this.videoUpdate);
    this.refs.ghost.removeEventListener('timeupdate', this.videoUpdateGhost);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    let video = this.props.videoDesktop.url;
    if (window.innerWidth < 769) video = this.props.videoMobile.url;
    this.setState({ video });
  }

  videoUpdate = () => {
    let time = this.refs.video.currentTime;

    if (time >= this.currentChapter.loopOut) {
      this.refs.video.currentTime = this.currentChapter.loopOut - 2;
    }
  }

  videoUpdateGhost = () => {
    let time = this.refs.ghost.currentTime;

    if (time >= this.previousChapter.loopOut) {
      this.refs.ghost.currentTime = this.previousChapter.loopOut - 2;
    }
  }

  start = () => {
    this.refs.video.play();
    this.refs.ghost.play();
  }

  next = () => {
    let currentChapterIndex = this.state.currentChapterIndex + 1;
    if (currentChapterIndex === this.props.chapters.length) currentChapterIndex = this.state.currentChapterIndex;
    this.setState({ currentChapterIndex });
  }

  prev = () => {
    let index = this.state.currentChapterIndex - 1;
    if (index === -1) index = 0;
    this.seek(index);
  }

  seek = (index) => {
    if (index === this.state.currentChapterIndex + 1) return this.next();

    this.refs.ghost.currentTime = this.refs.video.currentTime;

    this.setState({
      currentChapterIndex: index,
      previousChapterIndex: this.state.currentChapterIndex,
    }, () => {
      setTimeout(() => { this.setState({ seeking: true }); }, 50)
      setTimeout(() => { this.refs.video.currentTime = this.currentChapter.loopOut - 1.5; }, 200);
    })

    setTimeout(() => {
      this.setState({ seeking: false });
    }, 800)
  }

  get className() {
    return classNames({
      'process': true,
      'process--seeking': this.state.seeking
    })
  }

  render = () => (
    <Reveal fraction={0.5} onReveal={this.start}>
      <section className={this.className}>
        <div className="process__wrapper">
          <div className="process__video">
            <video src={this.state.video} ref="video" playsInline preload muted aria-describedby="products-video-info" />
            <video src={this.state.video} ref="ghost" playsInline preload muted className="process__video__ghost" aria-hidden />
          </div>

          <div className="process__bottom" id="products-video-info">
            {
              this.props.chapters.map((chapter, index) => {
                let className = classNames({
                  'process__chapter': true,
                  'process__chapter--active': index === this.state.currentChapterIndex
                });

                return (
                  <a className={className} onClick={() => this.seek(index)} role="button" tabindex="0">
                    <svg className="process__chapter__carret" width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden>
                      <path d="M1 10L9 2L17 10" stroke="#0115CE" stroke-width="2" />
                    </svg>

                    <div className="process__chapter__body">{chapter.body}</div>
                    <div className="process__chapter__number">0{index + 1}</div>
                  </a>
                )
              })
            }
          </div>
        </div>
      </section>
    </Reveal>
  )
}