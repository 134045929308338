import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Card } from "../../components/card";
import { Button } from "../../components/button";
import { TextAnimationHeading } from "../../components/text-animation-heading";
import { useMediaQuery } from "react-responsive";
import Reveal from "react-reveal/Reveal";
import RightArrow from '../card-carousel/right-arrow.inline.svg';
import LeftArrow from '../card-carousel/left-arrow.inline.svg';

const sectionConfig = {
  news: {
    slider: "S",
  },
  default: {
    slider: "disabled",
  },
  products: {
    slider: "disabled",
  },
  slider: {
    slider: "S",
  }
};

const settings = {
  autoplay: false,
  infinite: false,
  nextArrow: false,
  prevArrow: false,
  centerMode: true,
  arrows: false,
  centerPadding: 25,
  responsive: [
    {
      breakpoint: 719,
      settings: {
        centerPadding: 125,
      },
    },
    {
      breakpoint: 679,
      settings: {
        centerPadding: 125,
      },
    },
    {
      breakpoint: 575,
      settings: {
        centerPadding: 75,
      },
    },
    {
      breakpoint: 479,
      settings: {
        centerPadding: 25,
      },
    },
  ],
};


export const Cards = (props) => {
  const { ctaUrl, ctaTitle, title, cards, colorMode, containerType, articles, press } = props;
  const oddCount = cards.length > 1 && Boolean(cards.length % 2);
  const type = containerType.replace(" (duplicate)", "");
  const containerRef = useRef();

  const { slider } = sectionConfig[type];

  const [sliderState, setSliderState] = useState('beginning');
  
  const _classNames = classNames({
    "cards-section": true,
    [`cards-section--${containerType}`]: containerType,
    [`cards-section--${colorMode}`]: colorMode,
    [`cards-section--slider--${sliderState}`]: true,
    "cards-section--odd": oddCount,
    "cards-section--two-cards": cards.length === 2
  });

  const desktopView = useMediaQuery({ query: '(min-width:1024px)'});
  const responsiveView = useMediaQuery({ query: '(max-width:1024px)'});
  
  // If the cards are articles, map them to the correct card type
  cards.forEach((card) => { 
    if (card.__typename === 'DatoCMS_BlogArticleRecord') {
      card.cardType = 'news';
      card.viewPort= desktopView ? "isDesktop" : responsiveView && "isMobile";
      card.featureCard = true;
    }
    if (card.__typename === 'DatoCMS_PressReleaseRecord') {
      card.cardType = 'news';
      card.viewPort= desktopView ? "isDesktop" : responsiveView && "isMobile";
      card.featureCard = true;
    }
  });

  const renderCards = () => {
    if (slider && !articles && !press) {
      return (
        <div className="cards-section__container__row cards-section__container__row--slider" ref={containerRef} onScroll={() => scroll()}>
          { cards.map((value, key) => {
            return <Card card={value} key={`card--${key}`} />;
          }) }
        </div>
      )
    } else {
      return (
        <div className="cards-section__container__row">
          {cards.map((value, key) => {
            return <Card card={value} key={`card--${key}`} featureCard={value.__typename === 'DatoCMS_PressReleaseRecord' || value.__typename === 'DatoCMS_BlogArticleRecord' ? true : false}/>;
          })}
        </div>
      );
    }
  };

  const forward = () => {
    containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  }

  const back = () => {
    containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  }

  const scroll = () => {
    const position = containerRef.current.scrollLeft <= 50 ? 'beginning' : 'end';
    setSliderState(position);
  }

  return (
    <section className={_classNames}>
      <div className="cards-section__container">
        {title && <div className={`cards-section__title ${colorMode === 'dark' || colorMode === 'darker' ? "cards-section__title--color-white" : ""}`}>
          <Reveal effect="react-reveal--visible">
            <TextAnimationHeading textStyle="heading-4" text={title} />
          </Reveal>
        </div>}
        
        { containerType ? (
          renderCards()
        ) : (
          <div className="cards-section__container__row">
            {cards.map((value, key) => {
              return <Card card={value} key={`card--${key}`} />;
            })}
          </div>
        )}
       
        {ctaUrl && ctaTitle && (
          <div className="cards-section__button">
              <Reveal effect="react-reveal--visible">
                <Button
                  internal={ctaUrl?.slug}
                  text={ctaTitle}
                  news
                />
            </Reveal>
          </div>
        )}

        <div className="cards-section__arrow">
          <LeftArrow onClick={back} />
          <RightArrow onClick={forward} />
        </div>
      </div>
    </section>
  );
};