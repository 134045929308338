import React, { useState } from 'react';
import classNames from 'classnames';
import Select, { components } from 'react-select'
import CaretUp from './caretUp.inline.svg';
import CaretDown from './caretDown.inline.svg';
import dropdownStyles from './dropdownStyles.js'


export const FormField = (props) => {
  const { name, onChange, description, initialValue } = props;
  const [value, setValue] = useState(initialValue || '');

  const returnSelectedValue = (e) => {
    const target = {
      name: name,
      ...e
    }
    onChange({ target });
  }

  const _classNames = classNames({
    'form-field': true,
    'form-field--error': props.error,
    'form-field--disabled': props.disabled,
    'form-field__boxes__container': (props.type === "checkbox" || props.type === "radio"),
    [props.className] : props.className
  });

  const change = (e) => {
    setValue(e.target.value.trim());
    onChange(e);
  }


  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {(props.selectProps.menuIsOpen ? <CaretUp /> : <CaretDown />)}
      </components.DropdownIndicator>
    );
  }

  switch (props.type) {
    case "textarea":
      return (
        <div className={_classNames}>
          <label className="form-field__label">
            <p className="form-field__label__text">{props.label}</p>
            <p className="form-field__label__description">{description}</p>
            <textarea className="form-field__textarea"
              type="text" id="textarea-field"
              placeholder={props.placeholder}
              rows={props.rows || 6}
              cols={props.cols || 20}
              disabled={props.disabled && "disabled"}
              name={name} onChange={onChange}
            />
            {props.error &&
              <p className="form-field__error-helper">{props.errorHelper}</p>
            }
          </label>
        </div>
      )
    case "dropdown":
      return (
        <label className="form-field__label">
          <p className="form-field__label__text">{props.label}</p>
          <Select className={_classNames}
            classNamePrefix="form-field__dropdown"
            options={props.options} placeholder='- Select -'
            disabled={props.disabled && "disabled"}
            blurInputOnSelect={true}
            isSearchable={!props.disabled}
            styles={dropdownStyles}
            components={{ DropdownIndicator }}
            name={name}
            onChange={returnSelectedValue}
          />
          {props.error &&
            <p className="form-field__error-helper">{props.errorHelper}</p>
          }
        </label>
      )
    case "radio":
      return (
        <label className={_classNames}>
          <span className="form-field__label">{props.label}</span>
          <input type="radio" disabled={props.disabled && "disabled"} name={name} onChange={onChange} ></input>
          <span className="form-field__checkmark form-field__checkmark--radio"></span>
        </label>
      )
    case "checkbox":
      return (
        <label className={_classNames}>
          <span className="form-field__label">{props.label}</span>
          <input type="checkbox" disabled={props.disabled && "disabled"} name={name} onChange={onChange} ></input>
          <span className="form-field__checkmark form-field__checkmark--checkbox"></span>
        </label>
      )
    case "text":
    default:
      return (
        <div className={_classNames}>
          <label className="form-field__label" >
            <p className="form-field__label__text">{props.label}</p>
            <p className="form-field__label__description">{description}</p>
            <input className="form-field__input" type="text" value={value} placeholder={props.placeholder} disabled={props.disabled && "disabled"} name={name} onChange={change}/>
            {props.error &&
              <p className="form-field__error-helper">{props.errorHelper}</p>
            }
          </label>
        </div>
      )
  }

}
