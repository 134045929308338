import React from "react";
import { graphql } from "gatsby";

import Layout from "../layout";
import { ContentBlockWithLargeImage } from "../sections/content-block-with-large-image";
import { Footer } from "../sections/footer";
import { GlobalHeader } from "../sections/global-header";
import { ImageHeader } from "../sections/image-header";
import { ImageContent } from "../sections/image-content";
import { HomepageHeader } from "../sections/homepage-header";
import { TextHeader } from "../sections/text-header";
import { QuoteBlock } from "../sections/quote-block/index";
import { RelatedLinks } from "../sections/related-links";
import { ResourceList } from "../sections/resource-list";
import { Cards } from "../sections/cards";
import { SectionHeader } from "../sections/section-header";
import { OverlappingImages } from "../sections/overlapping-images";
import { SectionFeature } from "../sections/section-feature";
import { Profile } from "../sections/profile-section";
import { CardCarousel } from "../sections/card-carousel";
import { InlineCta } from "../sections/inline-cta";
import { Stats } from "../sections/stats";
import { Drawer } from "../sections/drawer";
import { Story2Columns } from "../sections/story-2-columns";
import { ContactForm } from "../sections/contact-form";
import { Testimonials } from "../sections/testimonials";
import { InfographicPlaceholder } from "../sections/infographic-placeholder";
import { CookieBanner } from "../components/cookie-banner";
import { Benefits } from "../sections/benefits";
import { ModularContent } from "../sections/modular-content";
import { FacilityFlyThrough } from "../infographics/facility-fly-through";
import { Metadata } from "../components/metadata";
import { RNGMetrics } from "../infographics/rng-metrics";
import { ProcessInfographic } from "../infographics/process";
import { ESGWheel } from "../infographics/esg-wheel";
import { FacilityMap } from "../infographics/facility-map";
import { SignatureBenefits } from "../infographics/signature-benefits";
import { Press } from "../sections/press";

export default class Page extends React.Component {
  sectionConductor(section) {
    switch (section.__typename) {
      case "DatoCMS_SectionHomepageHeaderRecord":
        return <HomepageHeader {...section} key={section.id} />;
      case "DatoCMS_SectionContentBlockWithLargeImageRecord":
        return <ContentBlockWithLargeImage {...section} key={section.id} />;
      case "DatoCMS_SectionImageHeaderRecord":
        return <ImageHeader {...section} key={section.id} />;
      case "DatoCMS_SectionTextHeaderRecord":
        return <TextHeader {...section} key={section.id} />;
      case "DatoCMS_SectionQuoteBlockRecord":
        return <QuoteBlock {...section} key={section.id} />;
      case "DatoCMS_SectionRelatedLinkRecord":
        return <RelatedLinks {...section} key={section.id} />;
      case "DatoCMS_SectionResourceListRecord":
        return <ResourceList {...section} key={section.id} />;
      case "DatoCMS_SectionCardContainerRecord":
        return <Cards {...section} key={section.id} />;
      case "DatoCMS_SectionSectionHeaderRecord":
        return <SectionHeader {...section} key={section.id} />;
      case "DatoCMS_SectionOverlappingImageRecord":
        return <OverlappingImages {...section} key={section.id} />;
      case "DatoCMS_SectionSectionFeatureRecord":
        return <SectionFeature {...section} key={section.id} />;
      case "DatoCMS_SectionImageContentRecord":
        return <ImageContent {...section} key={section.id} />;
      case "DatoCMS_SectionProfileRecord":
        return <Profile {...section} key={section.id} />;
      case "DatoCMS_SectionCardCarouselRecord":
        return <CardCarousel {...section} key={section.id} />;
      case "DatoCMS_SectionInlineCtaRecord":
        return <InlineCta {...section} key={section.id} />;
      case "DatoCMS_SectionStatisticRecord":
        return <Stats {...section} key={section.id} />;
      case "DatoCMS_SectionDrawerRecord":
        return <Drawer {...section} key={section.id} />;
      case "DatoCMS_SectionStroy2ColumnRecord":
        return <Story2Columns {...section} key={section.id} />;
      case "DatoCMS_SectionContactRecord":
        return <ContactForm {...section} key={section.id} />;
      case "DatoCMS_SectionTestimonialRecord":
        return <Testimonials {...section} key={section.id} />;
      case "DatoCMS_SectionInfographicPlaceholderRecord":
        return <InfographicPlaceholder {...section} key={section.id} />;
      case "DatoCMS_SectionBenefitTableRecord":
        return <Benefits {...section} key={section.id} />;
      case "DatoCMS_SectionModularContentRecord":
        return <ModularContent {...section} key={section.id} />;
      case 'DatoCMS_SectionPressReleaseGridRecord':
        return <Press {...section} key={section.id} />;

      // -- Infographics

      case "DatoCMS_SectionInfographicFacilityFlyThroughRecord":
        return <FacilityFlyThrough {...section} key={section.id} />;
      case "DatoCMS_SectionInfographicRngMetricRecord":
        return <RNGMetrics {...section} key={section.id} />;
      case "DatoCMS_SectionInfograpicProcessRecord":
        return <ProcessInfographic {...section} key={section.id} />;
      case "DatoCMS_SectionInfographicEsgWheelRecord":
        return <ESGWheel  {...section} key={section.id} />;
      case 'DatoCMS_SectionInfographicFacilityMapRecord':
        return <FacilityMap {...section} key={section.id} />;
      case 'DatoCMS_SectionInfographicSignatureBenefitRecord':
        return <SignatureBenefits {...section} key={section.id} />;
      
      default:
        return false;
    }
  }

  render = () => {
    const { sections, metadata } = this.props.data.dato.page;

    return (
      <Layout favicon={this.props.pageContext.favicon}>
        <Metadata {...metadata} />
        <GlobalHeader {...this.props.pageContext.header} />
        <main>{sections.map((section) => this.sectionConductor(section))}</main>
        <Footer {...this.props.pageContext.footer} />
        <CookieBanner {...this.props.pageContext.cookie} />
      </Layout>
    );
  };
}

export const query = graphql`
  query PageQuery($slug: String!) {
    dato {
      page(filter: { slug: { eq: $slug } }) {
        metadata {
          title
          description
          image {
            url
          }
        }
        title
        sections {
          ... on DatoCMS_SectionHomepageHeaderRecord {
            id
            __typename
            shadow
            title
            body
            gradient
            subjectBlocksList {
              eyebrow
              body
              link {
                slug
              }
            }
            desktopVideo {
              url
              format
            }
            mobileVideo {
              url
              format
            }
            tabletVideo {
              url
              format
            }
            backgroundimage {
              url
            }
          }

          ... on DatoCMS_SectionContentBlockWithLargeImageRecord {
            id
            __typename
            eyebrow
            title
            body
            biggerBody
            image {
              url
              alt
            }
            iconListTitle
            iconListItems {
              title
              body
              icon {
                url
              }
            }
            buttonText
            buttonLink {
              slug
            }
            color
            reversedLayout
            centeredContent
            containImage
          }

          ... on DatoCMS_SectionImageHeaderRecord {
            id
            __typename
            shadow
            id
            title
            eyebrow
            body
            gradient
            ctaButtonLink {
              slug
            }
            ctaButtonExternalLink
            iconList {
              icon {
                url
              }
              body
            }
            backgroundimage {
              url
            }
            ctaButton
          }

          ... on DatoCMS_SectionTextHeaderRecord {
            id
            __typename
            eyebrow
            title
            image {
              alt
              url
            }
            description
            backLink {
              slug
            }
            backLinkTitle
          }

          ... on DatoCMS_SectionQuoteBlockRecord {
            id
            __typename
            body
          }

          ... on DatoCMS_SectionRelatedLinkRecord {
            id
            __typename
            id
            title
            linkItem {
              id
              image {
                alt
                url
              }
              body
              slug {
                slug
              }
            }
          }

          ... on DatoCMS_SectionResourceListRecord {
            id
            __typename
            title
            id
            body
            listitems {
              resourceTitle
              resourceBody
              external
              download {
                url
              }
              internal {
                slug
              }
            }
          }

          ... on DatoCMS_SectionCardContainerRecord {
            id
            __typename
            id
            title
            containerType
            colorMode
            ctaUrl {
              slug
            }
            ctaTitle
            cards {
              ... on DatoCMS_ComponentCardRecord {
                id
                _publishedAt
                cardType
                body
                description
                heading
                color
                image {
                  url
                  alt
                }
                externalLink {
                  url
                  title
                }
                newsUrl {
                  ... on DatoCMS_BlogArticleRecord {
                    title
                    slug
                  }
                  ... on DatoCMS_PageRecord {
                    title
                    slug
                  }
                }
              }
              ... on DatoCMS_BlogArticleRecord {
                _publishedAt
                title
                slug
                date
                author
                category {
                  title
                }
                image {
                  url
                  alt
                }
              }
              ... on DatoCMS_PressReleaseRecord {
                _publishedAt
                title
                slug
                date
                author
                image {
                  url
                  alt
                }
              }
            }
          }

          ... on DatoCMS_SectionSectionHeaderRecord {
            id
            __typename
            eyebrow
            title
            body
            stacked
            color
            addBottomPadding
          }

          ... on DatoCMS_SectionOverlappingImageRecord {
            id
            __typename
            body
            imagePrimary {
              url
              alt
            }
            imageSecondary {
              url
              alt
            }
            reversedLayout
            bgColor
          }

          ... on DatoCMS_SectionSectionFeatureRecord {
            id
            __typename
            shadow
            id
            title
            eyebrow
            body
            ctaButton
            ctaButtonLink {
              slug
            }
            ctaButtonExternalLink
            backgroundimage {
              url
            }
            useViewportHeight
          }

          ... on DatoCMS_SectionImageContentRecord {
            id
            __typename
            title
            iconList {
              title
              body
              image {
                alt
                url
              }
            }
            backgroundimage {
              alt
              url
            }
          }

          ... on DatoCMS_SectionProfileRecord {
            id
            __typename
            cardsLayout
            backgroundMode
            personCard {
              body
              name
              jobTitle
              imagePerson {
                alt
                url
              }
              imageCar {
                alt
                url
              }
              detailPage {
                slug
              }
            }
            ctaButtonText
            ctaButtonLink {
              slug
            }
          }

          ... on DatoCMS_SectionCardCarouselRecord {
            id
            __typename
            carouselCards {
              subBody
              body
              image {
                alt
                url
              }
            }
          }

          ... on DatoCMS_SectionInlineCtaRecord {
            id
            __typename
            title
            body
            stacked
            color
            ctaButton
            ctaButtonLink {
              slug
            }
            externalLink
            formButton
          }

          ... on DatoCMS_SectionStatisticRecord {
            id
            __typename
            statsItems {
              eyebrow
              describtion
              prefix
              suffix
              value
            }
          }

          ... on DatoCMS_SectionDrawerRecord {
            id
            __typename
            section {
              category
              items {
                heading
                content
                expanded
              }
            }
          }

          ... on DatoCMS_SectionStroy2ColumnRecord {
            id
            __typename
            title
            body
            image {
              url
            }
            quote
          }

          ... on DatoCMS_SectionContactRecord {
            id
            __typename
            title
            netlifyFormName
            subject {
              value
              label
            }
            freeform
            showFileUpload
            contactInfo {
              eyebrow
              name
              description
              number
              email
            }
            btnText
            successfulSubscriptionMessage
            moreInformationTitle
            moreInformationBody
            termsAndConditions
            subjectPlaceholderValue
            messageAdditionalDescription
            fileUploadTitle
          }

          ... on DatoCMS_SectionTestimonialRecord {
            id
            __typename
            title
            timeout
            testimonials {
              quote
              nameOccupation
              image {
                url
                alt
              }
            }
          }

          ... on DatoCMS_SectionInfographicPlaceholderRecord {
            id
            __typename
            title
            colorTheme
          }

          ... on DatoCMS_SectionBenefitTableRecord {
            id
            __typename
            body
            title
            benefitCards {
              cardTitle
              image {
                alt
                url
              }
            }
          }

          ... on DatoCMS_SectionModularContentRecord {
            __typename
            content {
              __typename
              ... on DatoCMS_ContentTextRecord {
                text
              }
              ... on DatoCMS_ContentVideoRecord {
                url
              }
              ... on DatoCMS_ContentImageRecord {
                image {
                  url
                }
              }
            }
          }

          ... on DatoCMS_SectionInfographicFacilityFlyThroughRecord {
            __typename
            id
            videoDesktop {
              url
            }
            videoMobile {
              url
            }
            chapters {
              title
              body
              introIn
              introOut
              loopOut
            }
          }

          ... on DatoCMS_SectionInfographicRngMetricRecord {
            id
            title
            dialLabel
            chartLabel
          }
          
          ... on DatoCMS_SectionInfograpicProcessRecord {
            id
            title
            chapters {
              body
              introIn
              introOut
              loopOut
            }
            videoDesktop {
              url
            }
            videoMobile {
              url
            }
          }

          ... on DatoCMS_SectionInfographicEsgWheelRecord {
            id
            svgFile {
              url
            }
            mobile {
              url
            }
            viewOnDesktop
            layers {
              icon {
                url
              }
              title
              body
              relationshipLayers {
                title
                body
              }
            }
          }

          ... on DatoCMS_SectionInfographicFacilityMapRecord {
            id
            map {
              url
            }
            locations {
              title
              description
              layerId
              linksToPage {
                slug
              }
              icon {
                url
              }
            }
          }

          ... on DatoCMS_SectionInfographicSignatureBenefitRecord {
            id
            title
            defaultImage {
              url
            }
            topics {
              title
              features
              impact
              image {
                url
              }
            }
          }

          ... on DatoCMS_SectionPressReleaseGridRecord {
            id
            title
          }
        }       
      }
    }
  }
`;
