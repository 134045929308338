import React, { useState } from 'react';
import classNames from 'classnames';
import uuid from 'react-uuid'
import Caret from './caret.inline.svg';
import { HtmlText } from '../html-text';

export const Accordion = (props) => {

  const _classNames = classNames({
    'accordion': true
  });

  const [items, setItems] = useState(props.items);

  const handleClick = (index) => {
    setItems(
      items.map((item, i) =>
        i === index
          ? { ...item, expanded: !item.expanded }
          : { ...item, expanded: false }
      ))
  }

  return (
    <section className={_classNames} >
      <div className="accordion__container">
        {items.map((item, i) => (
          <div key={uuid()} className={`accordion__item ${item.expanded ? 'accordion__item--has-flag' : ''}`}>
            <div className="accordion__heading" onClick={() => handleClick(i)}>
              <button className="accordion__button">
                {item.heading}
                <span className="accordion__caret">
                  <Caret />
                </span>
              </button>
            </div>
            <div className="accordion__content">
              <HtmlText html={item.content} />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
